@import "~@angular/material/theming";


@include mat-core();

$ig-base-app-primary: mat-palette($mat-indigo);
$ig-base-app-primary-accent:  mat-palette($mat-pink, A200, A100, A400);

$ig-base-app-primary-warn:    mat-palette($mat-orange);

$ig-base-app-theme: mat-light-theme($ig-base-app-primary, $ig-base-app-primary-accent, $ig-base-app-primary-warn);

@include angular-material-theme($ig-base-app-theme);

table{
  width: 100%;
}
/*Responsive Table*/
@media screen and (max-width: 960px) {
  .mat-table {
    border: 0;
    vertical-align: middle
  }

  .mat-table caption {
    font-size: 1em;
  }

  /*  Enable this to hide header*/
  .mat-table .mat-header-cell {

    border: 10px solid;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .mat-table .mat-header-row {
    height: 100%;
  }

  .mat-table .mat-row {
    border-bottom: 5px solid #ddd;
    display: block;
    height: 100%;
  }
  /*
  .mat-table .mat-row:nth-child(even) {background: #CCC}
  .mat-table .mat-row:nth-child(odd) {background: #FFF}
  */
  .mat-table .mat-cell {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1em;
    text-align: right;
    font-weight: bold;
    min-height:30px;
    margin-bottom: 4%;
    padding: 5px;
  }
  .mat-table .mat-cell:before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: normal;
    font-size: .85em;
  }
  .mat-table .mat-cell:last-child {
    border-bottom: 0;
  }
  .mat-table .mat-cell:first-child {
    margin-top: 4%;
  }
}
/*Responsive Table*/

/*Responseive Flex row items*/
@media screen and (min-width: 1025px) {

  .two-per-row .mat-form-field, .two-per-row .row-item {
    width: 45%;
  }

  .three-per-row .mat-form-field, .three-per-row .row-item {
    width: 30%;
  }

  .four-per-row .mat-form-field, .four-per-row .row-item {
    width: 23%;
  }

  .five-per-row .mat-form-field, .five-per-row .row-item {
    width: 18%;
  }

}


.container{
  width: 100%;
}
.page-title {
  margin-bottom: 20px;
  border-bottom: 1px solid mat-color($ig-base-app-primary, 50);
  /*padding-bottom: 5px;*/
}

.page-title .button-icon{
  width: 64px;
}

.page-title .button-icon-text{
  margin: 8px;
}
.page-title .mat-card-header-text {
  margin: 0px;
}

.page-title-action {
  width: 100%;
}

.page-title-action > button, .page-title-action > a{
  float: right;
}
.mat-card-actions {
  width: 100%;
}
.mat-card-actions.pull-right > button, .mat-card-actions.pull-right > a{
  float: right;
}
.mat-dialog-container {
  padding: 0px !important;
}
.mat-list-item.active {
  background-color: mat-color($ig-base-app-primary, 50);
}
.mat-list-item:hover,
.mat-list-item:focus {
  >.mat-list-item:not(.expanded) {
    background-color: mat-color($ig-base-app-primary, 100) !important;
  }
}

.mat-list-item {
  padding: 8px 0;
}

.ig-background-primary {
  background-color: mat-color($ig-base-app-primary);
  color: white;
}

.ig-background-primary .mat-card-subtitle {
  color: $white-87-opacity;
}

.ig-card-as-panel {
  padding: 0px !important;
}
.ig-card-as-panel .mat-card-header {
  padding: 16px;
}
.ig-card-as-panel .mat-card-content {
  padding: 16px;
}
.ig-card-as-panel .mat-card-header-text {
  flex: 1 1 auto;
}
.facebook-auth-btn {
  background-color: #3B5998 !important;
  color: white !important;
}
.google-auth-btn {
  background-color: #DB4437 !important;
  color: white !important;
}
.twitter-auth-btn {
  background-color: #1DA1F2 !important;
  color: white !important;
}
