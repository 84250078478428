@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~swagger-ui/dist/swagger-ui.css";
/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.swagger-container .scheme-container {display: none}
.swagger-container .wrapper {background: white; padding: 10px;}


.mat-column-user {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 28% !important;
  width: 28% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

 
}
.mat-column-action {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 20% !important;
  width: 20% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;


}
.mat-column-remarks {
  word-wrap: break-word !important;
  white-space: unset !important;
  flex: 0 0 35% !important;
  width: 35% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;

  
}
